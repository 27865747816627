<script lang="ts" setup>
import usePusher from "@/composables/usePusher";
import colors from "tailwindcss/colors";
import Toast from "../components/overlay/Toast.vue";
import { useAuthStore } from "@/stores/auth";
import { useToastStore } from "@/stores/toast";
import NotificationSidebar from "@/components/overlay/NotificationSidebar.vue";
import NotificationButton from "@/components/notification/NotificationButton.vue";
import { useNotificationStore } from "@/stores/notification";
import NavSidebar from "~/components/overlay/NavSidebar.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

let { echo } = usePusher();
const notificationStore = useNotificationStore();
const toastStore = useToastStore();
const router = useRouter();
const authStore = useAuthStore();
const sidebarOpen = ref(false);

withDefaults(
  defineProps<{
    title: string;
    fullWidthSlot: boolean;
    hideSidebar: boolean;
  }>(),
  {
    title: "",
    fullWidthSlot: false,
    hideSidebar: false,
  },
);

const notificationSidebarOpen = ref(false);

authStore.$subscribe((mutation, state) => {
  if (!state.isAuthenticated) {
    router.push("/");
  }
});

onMounted(() => {
  notificationStore.fetchNotifications();
});

if (authStore.user?.id) {
  echo.channel(`private-users.${authStore.user.id}`).notification((e: any) => {
    console.log("Adding notification", e);
    notificationStore.notifications.push(e);
  });
}
// else {
//   authStore.clearUserData()
//   router.push('/')
// }
</script>

<template>
  <div class="h-screen flex overflow-hidden bg-gray-700">
    <NavSidebar
      v-if="!hideSidebar"
      :open="sidebarOpen"
      @close="sidebarOpen = false"
    />

    <NotificationSidebar
      v-if="!hideSidebar"
      :open="notificationSidebarOpen"
      @close="notificationSidebarOpen = false"
    />

    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div v-if="!hideSidebar" class="hidden md:flex p-2 justify-end">
        <NotificationButton @open="notificationSidebarOpen = true" />
      </div>
      <!-- Mobile Nav Header -->
      <div
        v-if="!hideSidebar"
        class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 flex justify-between"
      >
        <button
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          @click="sidebarOpen = true"
        >
          <span class="sr-only">Open sidebar</span>
          <FontAwesomeIcon
            :icon="['fad', 'bars']"
            size="lg"
            style="--fa-secondary-color: white"
            :style="{ '--fa-primary-color': colors.blue[400] }"
          />
        </button>
        <img src="/icon.svg" alt="New Descents" class="h-6 inline mt-2" />
        <NotificationButton @open="notificationSidebarOpen = true" />
      </div>
      <main class="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div
          :class="[
            fullWidthSlot ? 'w-full' : 'max-w-7xl mx-auto px-3 sm:px-6 md:px-8',
          ]"
        >
          <slot></slot>
        </div>
      </main>
    </div>

    <Toast :toast="toastStore.toast" @close="toastStore.reset()">
      {{ toastStore.toast.content }}
    </Toast>
  </div>
</template>
