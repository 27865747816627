import { acceptHMRUpdate, defineStore } from "pinia";
import { type Notification } from "@/models/notification";
import { type AsyncData } from "#app";

export const useNotificationStore = defineStore("NotificationStore", {
  state: () => {
    return {
      notifications: [] as Notification[],
    };
  },
  getters: {
    unreadCount(state) {
      return state.notifications.filter(
        (notification: Notification) => notification.read_at === null,
      ).length;
    },
  },
  actions: {
    async fetchNotifications() {
      const { data, error } = (await useApiFetch(
        "/api/notifications",
      )) as AsyncData<any, any>;
      if (error.value) return console.error(error.value);
      this.notifications = data.value.notifications;
    },

    async markAsRead(notification: Notification) {
      let { data, error } = (await useApiFetch(
        `/api/notifications/${notification.id}/read`,
      )) as AsyncData<any, any>;
      if (error.value) return console.error(error.value);
      const idx = this.notifications.findIndex((n) => n.id === notification.id);
      this.notifications[idx] = data.value.notification;
    },

    async deleteNotification(notification: Notification) {
      let { error } = (await useApiFetch(
        `/api/notifications/${notification.id}`,
        { method: "DELETE" },
      )) as AsyncData<any, any>;
      if (error.value) return console.error(error.value);
      const idx = this.notifications.findIndex((n) => n.id === notification.id);
      this.notifications.splice(idx, 1);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useNotificationStore, import.meta.hot),
  );
}
