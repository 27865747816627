<template>
  <button
    class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
    @click="emit('open')"
  >
    <span class="sr-only">Notifications</span>
    <!--    <font-awesome-layers full-width class="fa-lg">-->
    <FontAwesomeIcon
      :icon="['fad', 'bell']"
      size="lg"
      style="--fa-secondary-color: white"
      :style="{ '--fa-primary-color': colors.blue[400] }"
    />
    <!--      <font-awesome-layers-text v-if="notificationStore.unreadCount > 0" counter :value="notificationStore.unreadCount"-->
    <!--                                transform="shrink-9 right-18 down-6"/>-->
    <!--    </font-awesome-layers>-->
  </button>
</template>

<script lang="ts" setup>
import colors from "tailwindcss/colors";
import { useNotificationStore } from "@/stores/notification";

const notificationStore = useNotificationStore();
const emit = defineEmits(["open"]);
</script>
