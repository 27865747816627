<template>
  <li class="flex">
    <div class="group relative flex items-center py-6">
      <div class="-m-1 block flex-1 p-1">
        <div class="absolute inset-0" aria-hidden="true" />
        <div class="relative flex min-w-0 flex-1 items-center">
          <div class="relative inline-block flex-shrink-0">
            <font-awesome-layers class="fa-lg ml-3 mr-4">
              <FontAwesomeIcon
                :icon="['fad', 'tint']"
                class="text-cyan-400 h-6 w-6"
              />
              <FontAwesomeIcon
                v-if="notification.read_at === null"
                :icon="['fad', 'circle']"
                transform="shrink-9 up-7 right-9"
                class="text-red-400"
                swap-opacity
              />
            </font-awesome-layers>
          </div>
          <div class="flex">
            <div>
              <p
                class="text-sm font-medium text-gray-50 max-w-[310px] md:max-w-sm whitespace-normal"
              >
                {{ notification.data.name }}
              </p>
              <alert-title class="my-1" :alert="notification.data.alert" />
              <p
                class="text-sm text-gray-100 whitespace-normal"
                v-html="getThresholdValue(notification)"
              ></p>
            </div>
            <div class="flex flex-col items-center justify-around w-8 mx-2">
              <div @click="notificationStore.markAsRead(notification)">
                <FontAwesomeIcon
                  :icon="[
                    'fad',
                    notification.read_at ? 'envelope-open' : 'envelope',
                  ]"
                  class="text-cyan-400"
                  size="lg"
                  swap-opacity
                />
              </div>
              <div @click="notificationStore.deleteNotification(notification)">
                <FontAwesomeIcon
                  :icon="['fad', 'xmark']"
                  class="text-red-400"
                  size="lg"
                  swap-opacity
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>

<script lang="ts" setup>
import { type PropType } from "vue";
import useDates from "@/composables/useDates";
import AlertTitle from "@/components/page-element/AlertTitle.vue";
import { useNotificationStore } from "@/stores/notification";
import { type Notification } from "@/models/notification";

const notificationStore = useNotificationStore();
const { formatDateTime } = useDates();
const props = defineProps({
  notification: { type: Object as PropType<Notification>, required: true },
});

const getThresholdValue = (notification: Notification) => {
  let value = notification.data[notification.data.threshold_col];
  value = new Intl.NumberFormat().format(value);
  let col = notification.data.threshold_col;
  value += col === "temperature" ? "&deg;" : " " + col;
  return value + " @ " + formatDateTime(notification.data.sent_at);
};
</script>
