<template>
  <TransitionGroup
    name="list"
    tag="ul"
    class="flex-1 divide-y divide-gray-200 overflow-y-auto border-t-2"
  >
    <notification-list-item
      v-for="notification in notificationStore.notifications"
      :notification="notification"
      :key="notification.id"
    />
  </TransitionGroup>
</template>

<script lang="ts" setup>
import NotificationListItem from "@/components/notification/NotificationListItem.vue";
import { useNotificationStore } from "@/stores/notification";

const notificationStore = useNotificationStore();
</script>

<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
